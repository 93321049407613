import React from 'react'

import footStyles from './footer.module.css'

const currentYear = () => new Date().getFullYear()

const Footer = () => {
  return (
    <div className={footStyles.footer}>
      <div className={footStyles.content}>
        <em>Copyright &copy; {currentYear()} Flowers and Pie</em>
      </div>
    </div>
  )
}

export default Footer
