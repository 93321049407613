import React from 'react'
import { Link } from 'gatsby'
import indexStyles from './index.module.css'

import Layout from '../components/layout/layout'
import Image from '../components/image'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`flowers`, `pie`, `recipes`]} />
    <div className={indexStyles.page}>
      <p className={indexStyles.flowersandpie}>💐 🥧</p>
    </div>
  </Layout>
)

export default IndexPage
