import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import headerStyles from './header.module.css'

const Header = ({ siteTitle }) => (
  <div className={headerStyles.header}>
    <div className={headerStyles.content}>
      <Link to="/" className={headerStyles.title}>
        {siteTitle}
      </Link>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
